.epar__forgotpassword__form {
  padding: 0 20% 0 20%;
  height: 800px;
}

.epar__forgotpassword__have-code {
  margin-bottom: 50px;
}

.epar__forgotpassword__contact-admin--container {
  margin-top: 50px;
  background: #FF5722;
  padding: 25px;
}

.epar__forgotpassword__check-mail--container {
  margin-top: 50px;
  background: #4caf50;
  padding: 25px;
}

.epar__forgotpassword__contact-admin {
  color: white;
}
