@import "../abstracts/variables.scss";

.epar {
  &__header__sidebar-collapse--button {
    float: right;
    position: absolute;
    border: 0px;
    border-radius: 0px;
    right: -52px;
    bottom: 0px;
    height: 62px;
    z-index: 100;
    display: none;
  }

  &__header__sidebar-collapse--button:hover {
    background: $eparSidePanelCollapseButtonHoverColor;
  }
  
  &__header__sidebar-collapse--button:focus {
    background: $eparSidePanelCollapseButtonHoverColor;
  }

  &__header__action-button {
    height: 32px;
    color: #002140;
    margin-left: 20px;
  }

  &__header__user-action__menu {
    margin-top: 8px;
  }
  &__header__news__item {
    background: #fdf5f5;
  }
  &__header__news {
    background: $eparSideMenuBackgrounColor;
  }

  &__news--link {
    color: $eparColor;
  }

  &__header__news .ant-card-head {
    background:  #706F71;
  }

  &__news--popover > .ant-popover-inner, .ant-popover-inner-content {
    background: green;
  }

  &__header__tools {
    float: right;
  }

  &__header__mobile_tools {
    display: flex;
    margin-bottom: 14px;
    padding-left: 5%;
    padding-right: 25px;
  }

  &__mobile__header {
    background: #002140;
    padding: 0 5px;
    display: flex;

    .epar__logo {
      display: flex;
      justify-content: center
    }

    .epar__logo__label {
      display: flex;
      justify-content: center;
    }
    
    .epar__drawer-icon{
      color: #ffffff;
      background-color: #002140;
      border-style: none;
      top: 18px;
    }

    .epar__header__notifications-button {
      background: inherit;
      i {
        color: white;
      }
    }

    .notifications {
      position: absolute;
      right: 20px;
      top: 18px;
    }

  }

  &__header__notifications-button {
    border: none; 
  }
  &__header__user-settings--button {
    border: none; 
    border-bottom: solid 1px;
    border-radius: 0px;
  }
  
  &__header__notifications-content {
    min-width: 280px;
  }

&__mark_all-button{
  float: right;
  color: #218fa9;
  margin-bottom: 0%;
  position: relative;
  cursor: pointer;
}  
}

