@import url('https://fonts.googleapis.com/css?family=Maven+Pro');

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.epar {
 
 &__logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 10px;
  -webkit-transition: all .3s;
  transition: all .3s;
  background: $eparLogoColor;
  overflow: hidden;  
  img {
      height: 45px;
      display: inline-block;
      vertical-align: middle;
    }
 }

 &__logo__label {
   height: 67px;
   color: white;
   margin-bottom: 0px;
   display: inline-block;
   vertical-align: middle;
   font-size: 19px;
  }
 
 &__side-menu {
  background: $eparSideMenuBackgrounColor;
 }
 
 &__header{
  padding-right: 30px;
  background-color: #fff;
  height: 30pt;
 }
 
 &__content-container {
  // background-color: white !important;

  .ant-layout {
    background-color: white !important;
    background: white !important;
  }
 }

}

.ant-layout-sider {
  background: $eparSideMenuBackgrounColor;
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
  flex: 0 0 256px;
  max-width: 256px;
  min-width: 256px;
  width: 256px;
}

.ant-layout-sider .ant-layout-sider-collapsed {
  flex: 0 0 64px;
  max-width: 64px;
  min-width: 64px;
  width: 64px;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  color: $eparMenuItemColor;
  font-size: 16px;
  line-height: 0px;
}


.ant-btn {
  background-color: $eparSelecetdItemBackgroundColor;
  background-color: white;
  border: 1px solid #d9d9d9;
  color: rgba(0,0,0,.65);
}

.ant-table {
  font-size: 14px;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-left-color: white;
  border-right-color: white;
}
.ant-btn-group .ant-btn-primary:last-child:not(:last-child) {
  border-left-color: white;
  border-right-color: white;
  background-color: white;
  border: 1px solid #d9d9d9;
  color: rgba(0,0,0,.65);

}

.epar__content--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-breadcrumb {
  padding: 5px;
  color: transparent;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>a {
  color: hsla(0,0%,100%,.65);
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item>span>a {
  color: hsla(0,0%,100%,.65);
}

.ant-menu-dark .ant-menu-submenu-open  .ant-menu-item {
  background: #000c17;
}

.ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical  {
  padding: 16px 0px;
}

.ant-menu-item>a:hover, .ant-menu-item>span>a:hover {
  color: #fff;
}

.ant-menu-submenu-popup {
  width: 250px;
  background: #000c17;
  left: 90px;
}

.ant-menu-submenu-title {
  padding-left: 24px;
  line-height: 40px;
  height: 40px;

}

.ant-menu-item > span {
  font-size: 14px;
}

.ant-menu-submenu-title > span, a {
  font-size: 14px;
}

.epar__spinner__container {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 25%;
  background: #f0f2f5;
}

.epar__spinner {
  color: $eparColor;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  // background-color: $eparSelecetdItemBackgroundColor;
  background-color: $eparColor;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected > a{
    // color: $eparColor;
    color: white;
}

.view-pdf-modal {
  width: 1100px !important;
  @media screen and (max-width: 480px) {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  ._1CDf-Fj0nLQTDw84Sho5JS {
    height: 400px !important;
    width: 400px !important;
  }
  
  ._1CDf-Fj0nLQTDw84Sho5JS canvas{
    width: 300px !important;
    height: 450px !important;
  }

  .pdf-viewer-card {
    height: 500px;
  }

  .pdf-reader-modal {
    height: 600px;
  }
}

